<template>
  <div v-if="authUserIsCoach" class="container-xxl">
    <div class="row pt-3">
      <div class="col">
        <ul class="nav nav-tabs">
          <li v-for="(tab, tabIndex) in tabs" :key="`key-${tabIndex}`" class="nav-item">
            <a
              @click="activeTab = tab.slug"
              :class="{ active: tab.slug === activeTab }"
              class="nav-link"
              role="button"
            >{{ tab.name }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="my-3">
          <ExerciseList v-if="activeTab === 'exercise'"></ExerciseList>
          <CircuitList v-if="activeTab === 'circuit'"></CircuitList>
          <MetricList v-if="activeTab === 'metric'"></MetricList>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Library',
  components: {
    ExerciseList: () => import('@/controllers/library/exercise/ExerciseList'),
    CircuitList: () => import('@/controllers/library/circuit/CircuitList'),
    MetricList: () => import('@/controllers/library/metric/MetricList'),
  },
  computed: {
    ...mapState('auth', ['authUser']),
    ...mapGetters('auth', ['authUserIsCoach']),
    tabs() {
      return [
        {
          name: 'Exercise',
          slug: 'exercise',
        },
        {
          name: 'Circuit',
          slug: 'circuit',
        },
        {
          name: 'Metric',
          slug: 'metric',
        },
      ];
    },
  },
  data() {
    return {
      activeTab: 'exercise',
    };
  },
};
</script>
